<template>
  <div>
    <!--begin::Members-->
    <div class="card card-custom gutter-b">
        <div class="card-body">
            <p>You do not have permission to access this page.</p>
            <p>If you have permission please Press Reload Button.</p>
            <v-btn
                color="success"
                class="mr-4"
                ref="kt_store_submit"
                @click="reloadPage()"
              >
                Reload
              </v-btn>
        </div>
    </div>
  </div>      
</template>
<script>
import Vuetify from "vuetify";
export default {
    name: "AssessmentFill",
    vuetify: new Vuetify(),
    methods: {
        reloadPage() {
            this.$router.go();
        }
    },
}
</script>