var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.company_type == 'Small Enterprise')?_c('ul',{staticClass:"menu-nav"},[(_vm.permissions.includes('dashboard'))?_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])]),(_vm.user_tour_skip==0 && _vm.dashboard)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("This is your Dashboard")]),_c('p',[_vm._v("Here you will find important reminders for Tasks you should complete, and Renewals related to documents you have uploaded and accounts you have added.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{on:{"click":function($event){return _vm.skipTour('dashboard')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('dashboard')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,108569133)}):_vm._e(),(_vm.permissions.includes('assessments') && _vm.permissions.includes('company_admin_section') && _vm.is_company_admin == 1)?_c('router-link',{attrs:{"to":"/assessments"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-checking"}),_c('span',{staticClass:"menu-text"},[_vm._v("Risk Assessment")])]),(_vm.user_tour_skip==0 && _vm.risk_assessment)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Risk Assessments")]),_c('p',[_vm._v("Start your journey here. Risk assessments help you to quickly gain an understanding of the areas of vulnerability in your business.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('risk_assessment')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('risk_assessment')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,3390604235)}):_vm._e(),(_vm.permissions.includes('employees') && _vm.permissions.includes('company_admin_section'))?_c('router-link',{attrs:{"to":"/employees"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-avatar"}),_c('span',{staticClass:"menu-text"},[_vm._v("Manage Employees")])])])]}}],null,false,130664619)}):_vm._e(),(_vm.permissions.includes('business_accounts'))?_c('router-link',{attrs:{"to":"/business-account"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-list-3"}),_c('span',{staticClass:"menu-text"},[_vm._v("Business Accounts")])]),(_vm.user_tour_skip==0 && _vm.business_accounts)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Business Accounts")]),_c('p',[_vm._v("Keep track of usernames, passwords, renewal dates for all of your business accounts here. See how much you are spending annually for services like Constant Contact, Adobe and more! Have all your employees use this feature and you will always have account access information for all of your accounts. Never lose access to important accounts because an employee quits or is unavailable. Plus knowing what services you are signed up for and how much you are spending ensures that you aren’t wasting money on unused services.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('business_accounts')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('business_accounts')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,1250601959)}):_vm._e(),(_vm.permissions.includes('documents'))?_c('router-link',{attrs:{"to":"/documents"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-list-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Documents")])]),(_vm.user_tour_skip==0 && _vm.documents)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Documents")]),_c('p',[_vm._v("Stop searching your emails or file storage for important documents like insurance or contracts and get alerted when it’s time to renew them. Add important business operations files here for quick and easy access.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('documents')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('documents')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,3752379584)}):_vm._e(),(_vm.permissions.includes('resources'))?_c('router-link',{attrs:{"to":"/resources"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-list"}),_c('span',{staticClass:"menu-text"},[_vm._v("Resources")])]),(_vm.user_tour_skip==0 && _vm.resources)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Resources.")]),_c('p',[_vm._v("Store important resources like your company handbook and other companywide information and communications here and your employees will always know where to find it when they need it.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('resources')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('resources')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,2186369730)}):_vm._e(),_c('router-link',{attrs:{"to":"/training"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-rocket"}),_c('span',{staticClass:"menu-text"},[_vm._v("Training")])]),(_vm.user_tour_skip==0 && _vm.training)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Trainings")]),_c('p',[_vm._v("Assign training to your employees here. You can access free and paid training modules for topics such as sexual harassment, cybersecurity and more!")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('training')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('training')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,2833310745)}),_c('router-link',{attrs:{"to":"/my-trainings"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-rocket"}),_c('span',{staticClass:"menu-text"},[_vm._v("My Trainings")])])])]}}],null,false,2769293641)}),(_vm.permissions.includes('notifications'))?_c('router-link',{attrs:{"to":"/notifications"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-notification"}),_c('span',{staticClass:"menu-text"},[_vm._v("Notifications")])]),(_vm.user_tour_skip==0 && _vm.notifications)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Notifications")]),_c('p',[_vm._v("You’ll be alerted here whenever an item needs your attention.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('notifications')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('notifications')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,2195918498)}):_vm._e(),(_vm.permissions.includes('faq'))?_c('router-link',{attrs:{"to":"/faq"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-information"}),_c('span',{staticClass:"menu-text"},[_vm._v("F.A.Q.")])])])]}}],null,false,2694126846)}):_vm._e(),(_vm.permissions.includes('contact_us'))?_c('router-link',{attrs:{"to":"/contact-us"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-paper-plane"}),_c('span',{staticClass:"menu-text"},[_vm._v("Contact Us")])])])]}}],null,false,1455164513)}):_vm._e(),(_vm.permissions.includes('company_admin_section') && _vm.is_company_admin == 1)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/vue-bootstrap') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover","id":"admin_section_id"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(1),_c('router-link',{attrs:{"to":"/finance"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Manage Finance")])])])]}}],null,false,83918612)}),_c('router-link',{attrs:{"to":"/company-admin"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Manage Company Admin")])]),(_vm.user_tour_skip==0 && _vm.manage_company_admin)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Company Admins")]),_c('p',[_vm._v("Make a key employee an administrator of your AskCoda account. Remember that an admin will have access to all aspects of the account and will be able to see usernames and passwords for any accounts that have been added to AskCoda.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('manage_company_admin')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('manage_company_admin')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,3802938299)}),_c('router-link',{attrs:{"to":"/business-account-type"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Business Account Type")])]),(_vm.user_tour_skip==0 && _vm.ba_type)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Business Account Types")]),_c('p',[_vm._v("Add categories for the different types of business accounts you want to save in AskCoda. For example, MailChimp would be a marketing account so you would add Marketing account type here.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('ba_type')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('ba_type')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,3446661556)}),_c('router-link',{attrs:{"to":"/document-type"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Document Type")])]),(_vm.user_tour_skip==0 && _vm.doc_type)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Document Types")]),_c('p',[_vm._v("Just like Business Account Type, you can customize the categories for the kinds of documents you will add to AskCoda.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('doc_type')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('doc_type')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,1369172888)}),_c('router-link',{attrs:{"to":"/resource-type"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Resource Type")])]),(_vm.user_tour_skip==0 && _vm.res_type)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Resource Types")]),_c('p',[_vm._v("Just like Business Account Type, you can customize the categories for the kinds of resources you will add to AskCoda.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('res_type')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('res_type')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,726902065)}),_c('router-link',{attrs:{"to":"/committee/roles"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Roles")])]),(_vm.user_tour_skip==0 && _vm.tour_roles)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Roles")]),_c('p',[_vm._v("Customize the categories for you employees here. You can add supervisor, manager level or whatever titles you prefer.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('tour_roles')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('tour_roles')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,2444591531)}),_c('router-link',{attrs:{"to":"/permissions"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Permissions")])]),(_vm.user_tour_skip==0 && _vm.tour_permissions)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Permissions")]),_c('p',[_vm._v("Controls who sees what here. When you give your employees access to their own free AskCoda accounts, they can see whatever information you prefer.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('tour_permissions')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('tour_permissions')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,1338470664)}),_c('router-link',{attrs:{"to":"/settings"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Settings")])])])]}}],null,false,2916204600)})],1)]),(_vm.user_tour_skip==0 && _vm.admin_section)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("This is your Admin Section")]),_c('p',[_vm._v("Customize AskCoda here. You will be required to add Roles, Permissions, and the different types of Business Accounts and Documents you have.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('admin_section')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('admin_section')}}},[_vm._v("Got it!")])])])]):_vm._e()]):_vm._e(),_c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",on:{"click":_vm.onLogout}},[_c('i',{staticClass:"menu-icon flaticon-logout"}),_c('span',{staticClass:"menu-text"},[_vm._v("Logout")])])])],1):_c('ul',{staticClass:"menu-nav"},[(_vm.permissions.includes('dashboard'))?_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])]),(_vm.user_tour_skip==0 && _vm.dashboard)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("This is your Dashboard")]),_c('p',[_vm._v("Here you will find important reminders for Tasks you should complete, and Renewals related to documents you have uploaded and accounts you have added.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{on:{"click":function($event){return _vm.skipTour('dashboard')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('dashboard')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,108569133)}):_vm._e(),(_vm.permissions.includes('members') && _vm.user_permissions.includes('chapter_club_list_members'))?_c('router-link',{attrs:{"to":"/members"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-avatar"}),_c('span',{staticClass:"menu-text"},[_vm._v("Manage Members")])])])]}}],null,false,108824441)}):_vm._e(),(_vm.permissions.includes('contacts'))?_c('router-link',{attrs:{"to":"/contacts"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user"}),_c('span',{staticClass:"menu-text"},[_vm._v("Contacts")])])])]}}],null,false,720811720)}):_vm._e(),(_vm.permissions.includes('events'))?_c('router-link',{attrs:{"to":"/events"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-cd"}),_c('span',{staticClass:"menu-text"},[_vm._v("Events")])])])]}}],null,false,2167895474)}):_vm._e(),(_vm.permissions.includes('business_accounts'))?_c('router-link',{attrs:{"to":"/business-account"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-list-3"}),_c('span',{staticClass:"menu-text"},[_vm._v("Business Accounts")])]),(_vm.user_tour_skip==0 && _vm.business_accounts)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Business Accounts")]),_c('p',[_vm._v("Keep track of usernames, passwords, renewal dates for all of your business accounts here. See how much you are spending annually for services like Constant Contact, Adobe and more! Have all your employees use this feature and you will always have account access information for all of your accounts. Never lose access to important accounts because an employee quits or is unavailable. Plus knowing what services you are signed up for and how much you are spending ensures that you aren’t wasting money on unused services.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('business_accounts')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('business_accounts')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,1250601959)}):_vm._e(),(_vm.permissions.includes('documents'))?_c('router-link',{attrs:{"to":"/documents"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-list-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Documents")])]),(_vm.user_tour_skip==0 && _vm.documents)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Documents")]),_c('p',[_vm._v("Stop searching your emails or file storage for important documents like insurance or contracts and get alerted when it’s time to renew them. Add important business operations files here for quick and easy access.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('documents')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('documents')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,3752379584)}):_vm._e(),(_vm.permissions.includes('resources'))?_c('router-link',{attrs:{"to":"/resources"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-list"}),_c('span',{staticClass:"menu-text"},[_vm._v("Resources")])]),(_vm.user_tour_skip==0 && _vm.resources)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Resources.")]),_c('p',[_vm._v("Store important resources like your company handbook and other companywide information and communications here and your employees will always know where to find it when they need it.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('resources')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('resources')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,2186369730)}):_vm._e(),(_vm.permissions.includes('mytasks'))?_c('router-link',{attrs:{"to":"/mytasks"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-list-3"}),_c('span',{staticClass:"menu-text"},[_vm._v("My Tasks")])])])]}}],null,false,3358123068)}):_vm._e(),(_vm.permissions.includes('committee'))?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/committee') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(2),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(3),(_vm.user_permissions.includes('chapter_list_committees'))?_c('router-link',{attrs:{"to":"/committee/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Committee Manage")])])])]}}],null,false,115079673)}):_vm._e(),(_vm.user_permissions.includes('chapter_training_videos'))?_c('router-link',{attrs:{"to":"/trainingvideo/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Training Video")])])])]}}],null,false,392327884)}):_vm._e()],1)])]):_vm._e(),(_vm.permissions.includes('notifications'))?_c('router-link',{attrs:{"to":"/notifications"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-notification"}),_c('span',{staticClass:"menu-text"},[_vm._v("Notifications")])]),(_vm.user_tour_skip==0 && _vm.notifications)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Notifications")]),_c('p',[_vm._v("You’ll be alerted here whenever an item needs your attention.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('notifications')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('notifications')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,2195918498)}):_vm._e(),(_vm.permissions.includes('faq'))?_c('router-link',{attrs:{"to":"/faq"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-information"}),_c('span',{staticClass:"menu-text"},[_vm._v("F.A.Q.")])])])]}}],null,false,2694126846)}):_vm._e(),(_vm.permissions.includes('contact_us'))?_c('router-link',{attrs:{"to":"/contact-us"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-paper-plane"}),_c('span',{staticClass:"menu-text"},[_vm._v("Contact Us")])])])]}}],null,false,1455164513)}):_vm._e(),(_vm.permissions.includes('company_admin_section') && _vm.is_company_admin == 1)?_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/vue-bootstrap') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover","id":"admin_section_id"}},[_vm._m(4),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(5),_c('router-link',{attrs:{"to":"/finance"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Manage Finance")])])])]}}],null,false,83918612)}),_c('router-link',{attrs:{"to":"/company-admin"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Manage Company Admin")])]),(_vm.user_tour_skip==0 && _vm.manage_company_admin)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Company Admins")]),_c('p',[_vm._v("Make a key employee an administrator of your AskCoda account. Remember that an admin will have access to all aspects of the account and will be able to see usernames and passwords for any accounts that have been added to AskCoda.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('manage_company_admin')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('manage_company_admin')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,3802938299)}),_c('router-link',{attrs:{"to":"/business-account-type"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Business Account Type")])]),(_vm.user_tour_skip==0 && _vm.ba_type)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Business Account Types")]),_c('p',[_vm._v("Add categories for the different types of business accounts you want to save in AskCoda. For example, MailChimp would be a marketing account so you would add Marketing account type here.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('ba_type')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('ba_type')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,3446661556)}),_c('router-link',{attrs:{"to":"/document-type"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Document Type")])]),(_vm.user_tour_skip==0 && _vm.doc_type)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Document Types")]),_c('p',[_vm._v("Just like Business Account Type, you can customize the categories for the kinds of documents you will add to AskCoda.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('doc_type')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('doc_type')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,1369172888)}),_c('router-link',{attrs:{"to":"/resource-type"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Resource Type")])]),(_vm.user_tour_skip==0 && _vm.res_type)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Resource Types")]),_c('p',[_vm._v("Just like Business Account Type, you can customize the categories for the kinds of resources you will add to AskCoda.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('res_type')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('res_type')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,726902065)}),_c('router-link',{attrs:{"to":"/committee/roles"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Roles")])]),(_vm.user_tour_skip==0 && _vm.tour_roles)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Roles")]),_c('p',[_vm._v("Customize the categories for you employees here. You can add supervisor, manager level or whatever titles you prefer.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('tour_roles')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('tour_roles')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,2444591531)}),_c('router-link',{attrs:{"to":"/permissions"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Permissions")])]),(_vm.user_tour_skip==0 && _vm.tour_permissions)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("These are your Permissions")]),_c('p',[_vm._v("Controls who sees what here. When you give your employees access to their own free AskCoda accounts, they can see whatever information you prefer.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('tour_permissions')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('tour_permissions')}}},[_vm._v("Got it!")])])])]):_vm._e()])]}}],null,false,1338470664)}),_c('router-link',{attrs:{"to":"/settings"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Settings")])])])]}}],null,false,2916204600)})],1)]),(_vm.user_tour_skip==0 && _vm.admin_section)?_c('div',{staticClass:"user_tour_div bg-success"},[_c('h3',[_vm._v("This is your Admin Section")]),_c('p',[_vm._v("Customize AskCoda here. You will be required to add Roles, Permissions, and the different types of Business Accounts and Documents you have.")]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("Done? "),_c('a',{staticClass:"btn_skip",on:{"click":function($event){return _vm.skipTour('admin_section')}}},[_vm._v("Click here to skip")])]),_c('div',[_c('a',{staticClass:"btn_blck",on:{"click":function($event){return _vm.openNextTourPopup('admin_section')}}},[_vm._v("Got it!")])])])]):_vm._e()]):_vm._e(),_c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",on:{"click":_vm.onLogout}},[_c('i',{staticClass:"menu-icon flaticon-logout"}),_c('span',{staticClass:"menu-text"},[_vm._v("Logout")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-digital-marketing"}),_c('span',{staticClass:"menu-text"},[_vm._v("Admin Section")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Admin Section")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-digital-marketing"}),_c('span',{staticClass:"menu-text"},[_vm._v("Committee")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Committee")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-digital-marketing"}),_c('span',{staticClass:"menu-text"},[_vm._v("Admin Section")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Admin Section")])])])
}]

export { render, staticRenderFns }