<template>
  <ul class="menu-nav" v-if="company_type == 'Small Enterprise'">
    <router-link
      to="/dashboard"
      v-if="permissions.includes('dashboard')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && dashboard">
          <h3>This is your Dashboard</h3><p>Here you will find important reminders for Tasks you should complete, and Renewals related to documents you have uploaded and accounts you have added.</p>
          <div class="d-flex">
            <div>Done? <a @click="skipTour('dashboard')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('dashboard')">Got it!</a>
            </div>
          </div>
        </div>
      </li>
    </router-link>

    <router-link
      to="/assessments"
      v-if="permissions.includes('assessments') && permissions.includes('company_admin_section') && is_company_admin == 1"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-checking"></i>
          <span class="menu-text">Risk Assessment</span>
        </a>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && risk_assessment">
          <h3>These are your Risk Assessments</h3><p>Start your journey here. Risk assessments help you to quickly gain an understanding of the areas of vulnerability in your business.</p>
          <div class="d-flex">
            <div>Done? <a class="btn_skip" @click="skipTour('risk_assessment')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('risk_assessment')">Got it!</a>
            </div>
          </div>
        </div>
      </li>
    </router-link>

    <router-link
      to="/employees"
      v-if="permissions.includes('employees') && permissions.includes('company_admin_section')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-avatar"></i>
          <span class="menu-text">Manage Employees</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/business-account"
      v-if="permissions.includes('business_accounts')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list-3"></i>
          <span class="menu-text">Business Accounts</span>
        </a>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && business_accounts">
          <h3>These are your Business Accounts</h3><p>Keep track of usernames, passwords, renewal dates for all of your business accounts here. See how much you are spending annually for services like Constant Contact, Adobe and more! Have all your employees use this feature and you will always have account access information for all of your accounts. 
          Never lose access to important accounts because an employee quits or is unavailable. Plus knowing what services you are signed up for and how much you are spending ensures that you aren’t wasting money on unused services.</p>
          <div class="d-flex">
            <div>Done? <a class="btn_skip" @click="skipTour('business_accounts')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('business_accounts')">Got it!</a>
            </div>
          </div>
        </div>
      </li>
    </router-link>

    <router-link
      to="/documents"
      v-if="permissions.includes('documents')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list-1"></i>
          <span class="menu-text">Documents</span>
        </a>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && documents">
          <h3>These are your Documents</h3><p>Stop searching your emails or file storage for important documents like insurance or contracts and get alerted when it’s time to renew them. Add important business operations files here for quick and easy access.</p>
          <div class="d-flex">
            <div>Done? <a class="btn_skip" @click="skipTour('documents')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('documents')">Got it!</a>
            </div>
          </div>
        </div>
      </li>
    </router-link>

    <router-link
      to="/resources"
      v-if="permissions.includes('resources')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list"></i>
          <span class="menu-text">Resources</span>
        </a>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && resources">
          <h3>These are your Resources.</h3><p>Store important resources like your company handbook and other companywide information and communications here and your employees will always know where to find it when they need it.</p>
          <div class="d-flex">
            <div>Done? <a class="btn_skip" @click="skipTour('resources')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('resources')">Got it!</a>
            </div>
          </div>
        </div>
      </li>
    </router-link>
    <!-- <router-link
      to="#"
      v-if="subscription_type=='Free'"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="trainingAlert">
          <i class="menu-icon flaticon2-rocket"></i>
          <span class="menu-text">Training</span>
        </a>
      </li>
    </router-link> -->

    <router-link
      to="/training"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-rocket"></i>
          <span class="menu-text">Training</span>
        </a>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && training">
          <h3>These are your Trainings</h3><p>Assign training to your employees here. You can access free and paid training modules for topics such as sexual harassment, cybersecurity and more!</p>
          <div class="d-flex">
            <div>Done? <a class="btn_skip" @click="skipTour('training')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('training')">Got it!</a>
            </div>
          </div>
        </div>
      </li>
    </router-link>

    <router-link
      to="/my-trainings"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-rocket"></i>
          <span class="menu-text">My Trainings</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/notifications"
      v-if="permissions.includes('notifications')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-notification"></i>
          <span class="menu-text">Notifications</span>
        </a>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && notifications">
          <h3>These are your Notifications</h3><p>You’ll be alerted here whenever an item needs your attention.</p>
          <div class="d-flex">
            <div>Done? <a class="btn_skip" @click="skipTour('notifications')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('notifications')">Got it!</a>
            </div>
          </div>
        </div>
      </li>
    </router-link>

    <router-link
      to="/faq"
      v-if="permissions.includes('faq')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-information"></i>
          <span class="menu-text">F.A.Q.</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/contact-us"
      v-if="permissions.includes('contact_us')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-paper-plane"></i>
          <span class="menu-text">Contact Us</span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      v-if="permissions.includes('company_admin_section') && is_company_admin == 1"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
      id="admin_section_id"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-digital-marketing"></i>
        <span class="menu-text">Admin Section</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Admin Section</span>
            </span>
          </li>

          <router-link
            to="/finance"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Finance</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/company-admin"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Company Admin</span>
              </a>
              <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && manage_company_admin">
                <h3>These are your Company Admins</h3><p>Make a key employee an administrator of your AskCoda account. Remember that an admin will have access to all aspects of the account and will be able to see usernames and passwords for any accounts that have been added to AskCoda.</p>
                <div class="d-flex">
                  <div>Done? <a class="btn_skip" @click="skipTour('manage_company_admin')">Click here to skip</a></div>
                  <div>
                    <a class="btn_blck" @click="openNextTourPopup('manage_company_admin')">Got it!</a>
                  </div>
                </div>
              </div>
            </li>
          </router-link>

          <router-link
            to="/business-account-type"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Business Account Type</span>
              </a>
              <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && ba_type">
                <h3>These are your Business Account Types</h3><p>Add categories for the different types of business accounts you want to save in AskCoda. For example, MailChimp would be a marketing account so you would add Marketing account type here.</p>
                <div class="d-flex">
                  <div>Done? <a class="btn_skip" @click="skipTour('ba_type')">Click here to skip</a></div>
                  <div>
                    <a class="btn_blck" @click="openNextTourPopup('ba_type')">Got it!</a>
                  </div>
                </div>
              </div>
            </li>
          </router-link>

          <router-link
            to="/document-type"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Document Type</span>
              </a>
              <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && doc_type">
                <h3>These are your Document Types</h3><p>Just like Business Account Type, you can customize the categories for the kinds of documents you will add to AskCoda.</p>
                <div class="d-flex">
                  <div>Done? <a class="btn_skip" @click="skipTour('doc_type')">Click here to skip</a></div>
                  <div>
                    <a class="btn_blck" @click="openNextTourPopup('doc_type')">Got it!</a>
                  </div>
                </div>
              </div>
            </li>
          </router-link>

          <router-link
            to="/resource-type"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Resource Type</span>
              </a>
              <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && res_type">
                <h3>These are your Resource Types</h3><p>Just like Business Account Type, you can customize the categories for the kinds of resources you will add to AskCoda.</p>
                <div class="d-flex">
                  <div>Done? <a class="btn_skip" @click="skipTour('res_type')">Click here to skip</a></div>
                  <div>
                    <a class="btn_blck" @click="openNextTourPopup('res_type')">Got it!</a>
                  </div>
                </div>
              </div>
            </li>
          </router-link>

          <router-link
            to="/committee/roles"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Roles</span>
              </a>
              <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && tour_roles">
                <h3>These are your Roles</h3><p>Customize the categories for you employees here. You can add supervisor, manager level or whatever titles you prefer.</p>
                <div class="d-flex">
                  <div>Done? <a class="btn_skip" @click="skipTour('tour_roles')">Click here to skip</a></div>
                  <div>
                    <a class="btn_blck" @click="openNextTourPopup('tour_roles')">Got it!</a>
                  </div>
                </div>
              </div>
            </li>
          </router-link>

          <router-link
            to="/permissions"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Permissions</span>
              </a>
              <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && tour_permissions">
                <h3>These are your Permissions</h3><p>Controls who sees what here. When you give your employees access to their own free AskCoda accounts, they can see whatever information you prefer.</p>
                <div class="d-flex">
                  <div>Done? <a class="btn_skip" @click="skipTour('tour_permissions')">Click here to skip</a></div>
                  <div>
                    <a class="btn_blck" @click="openNextTourPopup('tour_permissions')">Got it!</a>
                  </div>
                </div>
              </div>
            </li>
          </router-link>

          <router-link
            to="/settings"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Settings</span>
              </a>
            </li>
          </router-link>

        </ul>
        </div>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && admin_section">
          <h3>This is your Admin Section</h3><p>Customize AskCoda here. You will be required to add Roles, Permissions, and the different types of Business Accounts and Documents you have.</p>
          <div class="d-flex">
            <div>Done? <a class="btn_skip" @click="skipTour('admin_section')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('admin_section')">Got it!</a>
            </div>
          </div>
        </div>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item"
    >
      <a class="menu-link" @click="onLogout">
        <i class="menu-icon flaticon-logout"></i>
        <span class="menu-text">Logout</span>
      </a>
    </li>
    
  </ul>

  <!-- Chapter sidebar -->
  <ul class="menu-nav" v-else>
    <router-link
      to="/dashboard"
      v-if="permissions.includes('dashboard')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && dashboard">
          <h3>This is your Dashboard</h3><p>Here you will find important reminders for Tasks you should complete, and Renewals related to documents you have uploaded and accounts you have added.</p>
          <div class="d-flex">
            <div>Done? <a @click="skipTour('dashboard')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('dashboard')">Got it!</a>
            </div>
          </div>
        </div>
      </li>
    </router-link>

    <router-link
      to="/members"
      v-if="permissions.includes('members') && user_permissions.includes('chapter_club_list_members')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-avatar"></i>
          <span class="menu-text">Manage Members</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/contacts"
      v-if="permissions.includes('contacts')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-user"></i>
          <span class="menu-text">Contacts</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/events"
      v-if="permissions.includes('events')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-cd"></i>
          <span class="menu-text">Events</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/business-account"
      v-if="permissions.includes('business_accounts')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list-3"></i>
          <span class="menu-text">Business Accounts</span>
        </a>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && business_accounts">
          <h3>These are your Business Accounts</h3><p>Keep track of usernames, passwords, renewal dates for all of your business accounts here. See how much you are spending annually for services like Constant Contact, Adobe and more! Have all your employees use this feature and you will always have account access information for all of your accounts. 
          Never lose access to important accounts because an employee quits or is unavailable. Plus knowing what services you are signed up for and how much you are spending ensures that you aren’t wasting money on unused services.</p>
          <div class="d-flex">
            <div>Done? <a class="btn_skip" @click="skipTour('business_accounts')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('business_accounts')">Got it!</a>
            </div>
          </div>
        </div>
      </li>
    </router-link>

    <router-link
      to="/documents"
      v-if="permissions.includes('documents')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list-1"></i>
          <span class="menu-text">Documents</span>
        </a>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && documents">
          <h3>These are your Documents</h3><p>Stop searching your emails or file storage for important documents like insurance or contracts and get alerted when it’s time to renew them. Add important business operations files here for quick and easy access.</p>
          <div class="d-flex">
            <div>Done? <a class="btn_skip" @click="skipTour('documents')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('documents')">Got it!</a>
            </div>
          </div>
        </div>
      </li>
    </router-link>

    <router-link
      to="/resources"
      v-if="permissions.includes('resources')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list"></i>
          <span class="menu-text">Resources</span>
        </a>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && resources">
          <h3>These are your Resources.</h3><p>Store important resources like your company handbook and other companywide information and communications here and your employees will always know where to find it when they need it.</p>
          <div class="d-flex">
            <div>Done? <a class="btn_skip" @click="skipTour('resources')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('resources')">Got it!</a>
            </div>
          </div>
        </div>
      </li>
    </router-link>

    <router-link
      to="/mytasks"
      v-if="permissions.includes('mytasks')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-list-3"></i>
          <span class="menu-text">My Tasks</span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      v-if="permissions.includes('committee')"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/committee') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-digital-marketing"></i>
        <span class="menu-text">Committee</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Committee</span>
            </span>
          </li>

          <router-link
            to="/committee/list"
            v-if="user_permissions.includes('chapter_list_committees')"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Committee Manage</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/trainingvideo/list"
            v-if="user_permissions.includes('chapter_training_videos')"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Training Video</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <router-link
      to="/notifications"
      v-if="permissions.includes('notifications')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-notification"></i>
          <span class="menu-text">Notifications</span>
        </a>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && notifications">
          <h3>These are your Notifications</h3><p>You’ll be alerted here whenever an item needs your attention.</p>
          <div class="d-flex">
            <div>Done? <a class="btn_skip" @click="skipTour('notifications')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('notifications')">Got it!</a>
            </div>
          </div>
        </div>
      </li>
    </router-link>

    <router-link
      to="/faq"
      v-if="permissions.includes('faq')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-information"></i>
          <span class="menu-text">F.A.Q.</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/contact-us"
      v-if="permissions.includes('contact_us')"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-paper-plane"></i>
          <span class="menu-text">Contact Us</span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      v-if="permissions.includes('company_admin_section') && is_company_admin == 1"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
      id="admin_section_id"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-digital-marketing"></i>
        <span class="menu-text">Admin Section</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Admin Section</span>
            </span>
          </li>

          <router-link
            to="/finance"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Finance</span>
              </a>
            </li>
          </router-link>

          <router-link
            to="/company-admin"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Manage Company Admin</span>
              </a>
              <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && manage_company_admin">
                <h3>These are your Company Admins</h3><p>Make a key employee an administrator of your AskCoda account. Remember that an admin will have access to all aspects of the account and will be able to see usernames and passwords for any accounts that have been added to AskCoda.</p>
                <div class="d-flex">
                  <div>Done? <a class="btn_skip" @click="skipTour('manage_company_admin')">Click here to skip</a></div>
                  <div>
                    <a class="btn_blck" @click="openNextTourPopup('manage_company_admin')">Got it!</a>
                  </div>
                </div>
              </div>
            </li>
          </router-link>

          <router-link
            to="/business-account-type"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Business Account Type</span>
              </a>
              <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && ba_type">
                <h3>These are your Business Account Types</h3><p>Add categories for the different types of business accounts you want to save in AskCoda. For example, MailChimp would be a marketing account so you would add Marketing account type here.</p>
                <div class="d-flex">
                  <div>Done? <a class="btn_skip" @click="skipTour('ba_type')">Click here to skip</a></div>
                  <div>
                    <a class="btn_blck" @click="openNextTourPopup('ba_type')">Got it!</a>
                  </div>
                </div>
              </div>
            </li>
          </router-link>

          <router-link
            to="/document-type"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Document Type</span>
              </a>
              <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && doc_type">
                <h3>These are your Document Types</h3><p>Just like Business Account Type, you can customize the categories for the kinds of documents you will add to AskCoda.</p>
                <div class="d-flex">
                  <div>Done? <a class="btn_skip" @click="skipTour('doc_type')">Click here to skip</a></div>
                  <div>
                    <a class="btn_blck" @click="openNextTourPopup('doc_type')">Got it!</a>
                  </div>
                </div>
              </div>
            </li>
          </router-link>

          <router-link
            to="/resource-type"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Resource Type</span>
              </a>
              <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && res_type">
                <h3>These are your Resource Types</h3><p>Just like Business Account Type, you can customize the categories for the kinds of resources you will add to AskCoda.</p>
                <div class="d-flex">
                  <div>Done? <a class="btn_skip" @click="skipTour('res_type')">Click here to skip</a></div>
                  <div>
                    <a class="btn_blck" @click="openNextTourPopup('res_type')">Got it!</a>
                  </div>
                </div>
              </div>
            </li>
          </router-link>

          <router-link
            to="/committee/roles"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Roles</span>
              </a>
              <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && tour_roles">
                <h3>These are your Roles</h3><p>Customize the categories for you employees here. You can add supervisor, manager level or whatever titles you prefer.</p>
                <div class="d-flex">
                  <div>Done? <a class="btn_skip" @click="skipTour('tour_roles')">Click here to skip</a></div>
                  <div>
                    <a class="btn_blck" @click="openNextTourPopup('tour_roles')">Got it!</a>
                  </div>
                </div>
              </div>
            </li>
          </router-link>

          <router-link
            to="/permissions"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Permissions</span>
              </a>
              <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && tour_permissions">
                <h3>These are your Permissions</h3><p>Controls who sees what here. When you give your employees access to their own free AskCoda accounts, they can see whatever information you prefer.</p>
                <div class="d-flex">
                  <div>Done? <a class="btn_skip" @click="skipTour('tour_permissions')">Click here to skip</a></div>
                  <div>
                    <a class="btn_blck" @click="openNextTourPopup('tour_permissions')">Got it!</a>
                  </div>
                </div>
              </div>
            </li>
          </router-link>

          <router-link
            to="/settings"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Settings</span>
              </a>
            </li>
          </router-link>
        </ul>
        </div>
        <div class="user_tour_div bg-success" v-if="user_tour_skip==0 && admin_section">
          <h3>This is your Admin Section</h3><p>Customize AskCoda here. You will be required to add Roles, Permissions, and the different types of Business Accounts and Documents you have.</p>
          <div class="d-flex">
            <div>Done? <a class="btn_skip" @click="skipTour('admin_section')">Click here to skip</a></div>
            <div>
              <a class="btn_blck" @click="openNextTourPopup('admin_section')">Got it!</a>
            </div>
          </div>
        </div>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item"
    >
      <a class="menu-link" @click="onLogout">
        <i class="menu-icon flaticon-logout"></i>
        <span class="menu-text">Logout</span>
      </a>
    </li>

  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import EventBus from "@/core/services/store/event-bus";
import { GET_COMPANY } from "@/core/services/store/user.module";
import { LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import axios from "axios";
import $ from 'jquery';

export default {
  name: "KTMenu",
  data() {
    return {
      company_type: "Small Enterprise",
      permissions: [],
      user_permissions: [],
      subscription_type: "Free",
      is_company_admin : 2,
      user_tour_skip : 0,
      dashboard : true,
      risk_assessment : false,
      business_accounts :false,
      documents:false,
      resources:false,
      training:false,
      notifications:false,
      admin_section:false,
      manage_company_admin:false,
      ba_type :false,
      doc_type :false,
      res_type :false,
      tour_roles:false,
      tour_permissions:false,
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    trainingAlert() {
        Swal.fire("Upgrade to a paid account to use this feature!", "", "info");
    },
    skipTour(currentPopup){
      let context = this;
      if(currentPopup == 'dashboard'){
        context.dashboard = false;
      }else if(currentPopup == 'risk_assessment'){
        context.risk_assessment = false;
      }else if(currentPopup == 'business_accounts'){
        context.business_accounts = false;
      }else if(currentPopup == 'documents'){
        context.documents = false;
      }else if(currentPopup == 'resources'){
        context.resources = false;
      }else if(currentPopup == 'training'){
        context.training = false;
      }else if(currentPopup == 'notifications'){
        context.notifications = false;
      }else if(currentPopup == 'admin_section'){
        context.admin_section = false;
      }else if(currentPopup == 'manage_company_admin'){
        context.manage_company_admin = false;
        $('#admin_section_id').removeClass('menu-item-open');
      }else if(currentPopup == 'ba_type'){
        context.ba_type = false;
        $('#admin_section_id').removeClass('menu-item-open');
      }else if(currentPopup == 'doc_type'){
        context.doc_type = false;
        $('#admin_section_id').removeClass('menu-item-open');
      }else if(currentPopup == 'res_type'){
        context.res_type = false;
        $('#admin_section_id').removeClass('menu-item-open');
      }else if(currentPopup == 'tour_roles'){
        context.tour_roles = false;
        $('#admin_section_id').removeClass('menu-item-open');
      }else if(currentPopup == 'tour_permissions'){
        context.tour_permissions = false;
        $('#admin_section_id').removeClass('menu-item-open');
      }   
      EventBus.$emit("SIDE_MENU_OVAERFLOW", false);
      axios({
        method: "post",
        url: "update-usertourskip-status",
        baseURL: process.env.VUE_APP_ACBACK_URL,
      });
    },
    openNextTourPopup(currentPopup){
      let context = this;
      if(currentPopup == 'dashboard'){
        context.dashboard = false;
        if(context.company_type == 'Small Enterprise'){
          if(context.is_company_admin == 1){
            context.risk_assessment = true;
          }else{
            context.business_accounts = true;
          }
        }else{
          context.business_accounts = true;
        }
      }else if(currentPopup == 'risk_assessment'){
        context.risk_assessment = false;
        context.business_accounts = true;
      }else if(currentPopup == 'business_accounts'){
        context.business_accounts = false;
        context.documents = true;
      }else if(currentPopup == 'documents'){
        context.documents = false;
        context.resources = true;
      }else if(currentPopup == 'resources'){
        context.resources = false;
        if(context.company_type == 'Small Enterprise'){
          context.training = true;
        }else{
          context.notifications = true;
        }
      }else if(currentPopup == 'training'){
        context.training = false;
        context.notifications = true;
      }else if(currentPopup == 'notifications'){
        context.notifications = false;
        if(context.is_company_admin == 1){
          context.admin_section = true;
          // context.manage_company_admin = true;
        }else{
          EventBus.$emit("USER_TOUR_PROFILE", true);
        }
      }else if(currentPopup == 'admin_section'){
        context.admin_section = false;
        context.manage_company_admin = true;
        $('#admin_section_id').addClass('menu-item-open');
      }else if(currentPopup == 'manage_company_admin'){
        context.manage_company_admin = false;
        context.ba_type = true;
      }else if(currentPopup == 'ba_type'){
        context.ba_type = false;
        context.doc_type = true;
      }else if(currentPopup == 'doc_type'){
        context.doc_type = false;
        context.res_type = true;
      }else if(currentPopup == 'res_type'){
        context.res_type = false;
        context.tour_roles = true;
      }else if(currentPopup == 'tour_roles'){
        context.tour_roles = false;
        context.tour_permissions = true;
      }else if(currentPopup == 'tour_permissions'){
        context.tour_permissions = false;
        $('#admin_section_id').removeClass('menu-item-open');
        EventBus.$emit("USER_TOUR_PROFILE", true);
        EventBus.$emit("SIDE_MENU_OVAERFLOW", false);
      }
    }
  },
  computed: {
    ...mapGetters(["getCompany", "getPermissions", "getUserPermissions", "getSubscriptionType"])
  },
  watch: {
    getCompany: function(newVal) {
      this.company_type = newVal.company_type
        ? newVal.company_type
        : "Small Enterprise";
    },
    getPermissions: function(newVal) {
      this.permissions = newVal;
    },
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
    }
  },
  mounted() {
    let context = this;
    this.company_type = this.getCompany.company_type;
    this.$store.dispatch(GET_COMPANY);
    EventBus.$on("GET_COMPANY", function() {});
    EventBus.$on("GET_SUBSCRIPTION_TYPE", function(newVal) {
        context.subscription_type = newVal;
    });
    EventBus.$on("GET_IS_COMPANY_ADMIN", function(newVal) {
        context.is_company_admin = newVal;
    });
    EventBus.$on("GET_USER", function(newVal) {
      context.user_tour_skip = newVal.user_tour_skip;
      if(context.user_tour_skip == 0){
        EventBus.$emit("SIDE_MENU_OVAERFLOW", true);
      }
    });
  }
};
</script>
