<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay && permission"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <v-app>
                <transition name="fade-in-up" :permission="permission">
                    <permission v-if="!permission" />
                    <router-view v-if="permission" />
                </transition>
              </v-app>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import Permission from "@/view/layout/Permission.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import EventBus from "@/core/services/store/event-bus";
import $ from 'jquery';

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTStickyToolbar,
    KTScrollTop,
    Loader,
    Permission
  },
  data() {
    return {
      permission: false,
      permissions: [],
      user_permissions: []
    }
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  watch: {
    getPermissions: function(newVal) {
      this.permissions = newVal;
      var permission_name = this.getPermissionName();
      if(this.permissions.includes(permission_name)){
        this.permission = true;
      } else {
        this.permission = false;
      }
    },
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
      var permission_name = this.getPermissionName();
      if(this.user_permissions.includes(permission_name)){
        this.permission = true;
      }
    }
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);

    EventBus.$on("SIDE_MENU_OVAERFLOW", function(newVal) {
      if(newVal){
        $('.d-flex.flex-row.flex-column-fluid.page').addClass('kt_aside_parent_div');
      }else{
        $('.d-flex.flex-row.flex-column-fluid.page').removeClass('kt_aside_parent_div');
      }
    });
  },
  methods: {
    getPermissionName() {
      var route_name = this.$route.name;
      if(route_name == "permissions"
        || route_name == "businessAccountTypeList" || route_name == "businessAccountTypeCreate" || route_name == "businessAccountType.edit" || route_name == "businessAccountType.show"
        || route_name == "documentTypeList" || route_name == "documentTypeCreate" || route_name == "documentType.edit" || route_name == "documentType.show"
        || route_name == "resourceTypeList" || route_name == "resourceTypeCreate" || route_name == "resourceType.edit" || route_name == "resourceType.show") {
        return "company_admin_section";
      }
      if(route_name == "dashboard" || route_name == "resetpassword.edit") {
        return "dashboard";
      }
      if(route_name == "employeesList" || route_name == "employeesCreate" || route_name == "employees.edit") {
        return "employees";
      }
      if(route_name == "assessments" || route_name == "AssessmentList" || route_name == "viewactionitems" || route_name == "assessment.fill" || route_name == "assessment.restart") {
        return "assessments";
      }
      if(route_name == "businessAccountList" || route_name == "businessAccountCreate" || route_name == "businessAccount.edit" || route_name == "businessAccount.show") {
        return "business_accounts";
      }
      if(route_name == "documents" || route_name == "documentsList" || route_name == "documents.edit" || route_name == "documents.renew") {
        return "documents";
      }
      if(route_name == "resourceList" || route_name == "resourceCreate" || route_name == "resource.edit") {
        return "resources";
      }
      if(route_name == "training.list" || route_name == "training.available" || route_name == "training.enroll") {
        return "training";
      }
      if(route_name == "my-trainings.list" || route_name == "my-trainings.view") {
        return "my-trainings";
      }
      if(route_name == "notificationsList") {
        return "notifications";
      }
      if(route_name == "faq-list") {
        return "faq";
      }
      if(route_name == "countactus.form") {
        return "contact_us";
      }
      if(route_name == "membersList" || route_name == "membersCreate" || route_name == "members.edit" || route_name == "members.manage") {
        return "members";
      }
      if(route_name == "ContactList" || route_name == "ContactCreate" || route_name == "contact.edit") {
        return "contacts";
      }
      if(route_name == "eventsList" || route_name == "eventsCreate" || route_name == "events.edit" 
      || route_name == "events.show" || route_name == "events.view") {
        return "events";
      }
      if(route_name == "mytasks") {
        return "mytasks";
      }
      if(route_name == "committee.roles" || route_name == "committee.roles.add" || route_name == "committee.roles.edit" || route_name == "committee.roles.task.create"
      || route_name == "CommitteeList" || route_name == "committeeCreate" || route_name == "committee.edit") {
        return "committee";
      }
      if(route_name == "Subscription") {
        return "subscription";
      }
      if(route_name == "companyAdminList" || route_name == "companyAdminCreate" || route_name == "companyAdmin.edit") {
        return "company_admin";
      }
      if(route_name == "financeList") {
        return "finance";
      }
      
      return "";
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "getPermissions",
      "getUserPermissions"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    }
  }
};
</script>
